<template>
	<div>
		<div class="box" v-if="table" id="mare_monte">
			<template>
				<CustomTable
					id_table="contract"
					:busy.sync="table_busy"
					primaryKey="contract_id"
					:hide_if_empty="true"
					:rawColumns="rawColumnNames"
					:hrefsRoutes="config_table_hrefs"
					:externSlotColumns="extern_slot_columns"
					:base-filters="filters"
					:transformer="['ContractTransformer', 'withAllAvenantArticles']"
					ref="contractTable"
					:columsAddBegin="['preview']"
				>
					<template v-slot:[`custom-slot-cell(avenant.avenant_free)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_free" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:[`custom-slot-cell(avenant.type_monte.contract_type_monte.contracttypemonte_label)`]="{ data }">
						{{ $t(data.avenant.type_monte.contract_type_monte.contracttypemonte_label) }}
					</template>
					<template v-slot:[`custom-slot-cell(avenant.avenant_dn_bool)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_dn_bool" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:[`custom-slot-cell(avenant.avenant_as_bool)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_as_bool" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:[`custom-slot-cell(avenant.avenant_revue_bool)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_revue_bool" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:custom-slot-cell(formatted_invoices)="{ data }">
						<p v-for="invoice in data.formatted_invoices" :key="invoice.invoice_id">
							<a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">{{invoice.invoice_num}}</a> ({{ $t('invoice.invoice_balance') }} : {{ invoice.invoice_balance }})
						</p>
					</template>
					<template v-slot:[`custom-slot-cell(paillettes_date)`]="{ data }">
						{{ data.paillettes_date }}
					</template>
					<template v-slot:[`custom-slot-cell(doses_date)`]="{ data }">
						{{ data.doses_date }}
					</template>
					<template v-slot:[`custom-slot-cell(preview)`]="{ data }">
						<a href="" class="" @click.prevent="quickContractPreview(data.contract_id)">
							<font-awesome-icon :icon="['fas', 'eye']" />
						</a>
					</template>
				</CustomTable>
			</template>
		</div>
		<div v-else>
			<ContractShutter :contract_id="contract_id" :from="'mareList'" :version="2"></ContractShutter>
		</div>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
		
		<ModalContractEnvoie ref="modal_contract_envoie" />
		<ModalContractModifRapide ref="modal_contract_modif_rapide"/>
		<ModalSendContract ref="modal_send_contract" @submit="refreshTable"/>
		<ModalActDGLot ref="modal_act_dg_lot" @submit="refreshTable"/>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Model from "@/mixins/Model.js"
	import Common from '@/assets/js/common'
	import Invoice from "@/mixins/Invoice.js"

	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import Syndic from "@/mixins/Syndic.js"

	export default {
		name: "ContractMareList",
		mixins: [TableAction, Navigation, ContractMixin, Model, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Syndic, Invoice],
		props: ['horse'],
		data () {
			return {
				table_busy: false,
				base64: null,
				table: true,
				contract_id: null,
				contract_ids: [],
				model_selected: {},
				models: [],
				processing: false,
				events_tab: {
				    'TableAction::goToAddContract': async () => {
				    	this.contract_id = await this.addContractOnlineFromHorse(this.horse.horse_id);
				    	this.$router.push({name: "ContractFormv2", params: {contract_id: parseInt(this.contract_id), from: "mareList"}})
				    	this.table = false
				    },
				    'TableAction::goToEditContract': (params) => {
				    	this.$router.push({name: "ContractFormv2", params: {contract_id: parseInt(params.contract_id)}})
						this.contract_id = parseInt(params.contract_id);
						this.table = false
					},
					'TableAction::goToPrintContract': (contract_ids) => {
						this.callPrintContracts(contract_ids)
					},
					'TableAction::goToSendContract': (contract_ids) => {
						this.openModalSendContract(contract_ids)
					},
					'TableAction::goToDeleteContract': (contract_ids) => {
						this.deleteContract(contract_ids)
					},
					'TableAction::goToFastEditContract': (contract) => {
						this.openModalFastEditContract(contract[0])
					},
					'TableAction::goToMarkContractAsSended': (contract_ids) => {
						this.markAsSended(contract_ids)
					},
					'TableAction::goToActDgLotContract': (contracts) => {
						this.openModalActDGLot(contracts)
					},
					'TableAction::goToReindexContract': (contracts) => {
						this.reindexContract(contracts)
					},
					'TableAction::goToCourrierContract': (contract_ids) => {
						this.callCourrierContracts(contract_ids)
					},
				},
				extern_slot_columns: [
					'avenant.avenant_free',
					'avenant.avenant_dn_bool',
					'avenant.avenant_as_bool',
					'avenant.avenant_revue_bool',
					'avenant.type_monte.contract_type_monte.contracttypemonte_label',
					'formatted_invoices',
					'paillettes_date',
					'doses_date',
					'preview'
				],
				rawColumnNames: [
					"articles",
					"seasonmarestallion.seasonmarestallion_status",
					"contract_note",
					"avenant.avenant_justification",
					"avenant.avenant_comment"
				],
				config_table_hrefs: {
					'contract_num': {
						routeUniqueName: 'ContractFormv2',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract_id'
						}
					},
				    'contract_tiers[0].tiers.tiers_rs': {
				        routeUniqueName: 'tiersTableauBord',
				        routeName: 'tiersTableauBord',
				        params: {
				            tiers_id: 'contract_tiers.0.tiers.tiers_id'
				        }
				    },
					'avenant.horse.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'avenant.avenant_horse'
						}
					},
				    'config.horse.horse_nom': {
				        routeUniqueName: 'StallionFiche',
				        routeName: 'horseFiche',
				        params: {
				            horse_id: 'config.horse.horse_id'
				        }
				    },
					'avenant.cmep.tiers_rs': {
						routeUniqueName: 'cmepFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'avenant.cmep.tiers_id'
                        }
                    },
					'courtier.tiers_rs': {
						routeUniqueName: 'courtierFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'courtier.tiers_id'
                        }
                    },
					'avenant.porteuse.horse_nom': {
						routeUniqueName: 'MarePorteuseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'avenant.porteuse.horse_id'
						}
					}
				}
			}
		},
		mounted() {
			this.init_component()
			EventBus.$on("Swipper::TabClicked", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					if(this.$refs.contractTable) {
						this.$refs.contractTable.refreshTable()
					}
				}
			})

			EventBus.$on("ContractMareList::reloadTabs", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					if(this.$refs.contractTable) {
						this.$refs.contractTable.refreshTable()
					}
				}
			})
		},
		methods: {
			async init_component() {
				this.table = true
				this.table_busy = true
				this.contract_id = null

				const table = await this.$storage.db.t('contract_avenant')

				// Récupérer les avenants de la jument
				const avenants = await table.where('avenant_horse').equals(this.horse.horse_id)

				// Vérifier si c'est le dernier avenant du contrat
				let contract_ids = new Set()
				await avenants.each(async (avenant) => {
					const has_next_avenants = await table.where('avenant_contract').equals(avenant.avenant_contract)
						.and((contract_avenant) => {
							return parseInt(contract_avenant.avenant_num) > parseInt(avenant.avenant_num)
						})
						.count()
					if (!has_next_avenants) {
						contract_ids.add(avenant.avenant_contract)
					}
				})

				// Transformation du Set en Array
				this.contract_ids = []
				contract_ids.forEach(contract_id => {
					this.contract_ids.push(contract_id)
				})
			},
			async checkForm() {
				if(!this.processing) {
					this.processing = true
				}
			},
			async deleteContract(contract_ids) {
				try {
					await this.deleteContracts(contract_ids)
				}
				catch(error) {
					EventBus.$emit('App::failureToast', 'error.DC')
				}
				this.$refs.contractTable.refreshTable()
			},
			async callSendContracts(contract_ids) {
				let result = await this.sendMailContracts(contract_ids)
				if(result != null){
					this.successToast('monte.send_success')
					await this.markSendedContract(contract_ids)
					.then(() => {
						this.$refs.contractTable.refreshTable()
					})
				}
				else
				{
					this.failureToast('monte.send_error')
				}
				EventBus.$emit("TableAction::stopSpin")
			},
			async callPrintContracts(contract_ids) {
				await Common.asyncForEach(contract_ids, async (contract_id) => {
					let result = await this.printContract(parseInt(contract_id))
					if(result == null){
						this.failureToast('monte.print_error')					
					}
					EventBus.$emit("TableAction::stopSpin")
				})
			},
			openModalFastEditContract(contract) {
				let contract_avenant = contract.avenant
				let infos_contract = {
					contract_id: contract.contract_id,
					contract_note: contract.contract_note,
					contractconfig_horse: contract.config.contractconfig_horse,
					contractconfig_season: contract.config.contractconfig_season
				}
				let infos_avenant = {
					avenant_id: contract_avenant.avenant_id, 
					avenant_dn: contract_avenant.avenant_dn,
					avenant_dn_bool: contract_avenant.avenant_dn_bool,
					avenant_dps: contract_avenant.avenant_dps, 
					avenant_as_bool: contract_avenant.avenant_as_bool, 
					avenant_date_signature: contract_avenant.avenant_date_signature, 
					avenant_date_validation: contract_avenant.avenant_date_validation, 
					avenant_status: contract_avenant.avenant_status,
					avenant_type_contrat: contract_avenant.avenant_type_contrat,
					avenant_comment: contract_avenant.avenant_comment
				}
				this.$refs.modal_contract_modif_rapide.openModal(infos_contract, infos_avenant)
			},
			async markAsSended(contract_ids) {
				await this.markSendedContract(contract_ids)
				.then(() => {
					this.$refs.contractTable.refreshTable()
				})
			},
			openModalSendContract(contract_ids) {
				this.$refs.modal_send_contract.openModal(contract_ids)
			},
			refreshTable() {
				EventBus.$emit("TableAction::stopSpin")
				this.$refs.contractTable.refreshTable()
			},
			async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },
			openModalActDGLot(contracts) {
				this.$refs.modal_act_dg_lot.openModalContract(contracts)
			},
			async reindexContract(contracts) {
				const contract_id = contracts.map(contract => contract.contract_id)
				await this.forceIndexationContract(contract_id)
				this.$refs.contractTable.refreshTable()
			},
			async callCourrierContracts(contract_ids) {
				this.$refs.modal_contract_envoie.openModal(contract_ids)
			},
			async quickContractPreview(contract_id) {
                this.$refs.modelPreview.show()
                let retour = await this.printContract(contract_id, true)
                if(retour) {
                    this.base64 = retour
                }
            }
		},
		computed: {
			filters() {
				return {
					// Nom unique du filtre
					is_validated: {
						// Colonne sur laquelle appliquer le filtre
						column: 'contract_id',
						// Parmis les opérateurs classiques disponibles pour les utilisateurs (
						operator: 'isInList',
						// Valeur à comparer
						value: this.contract_ids
					},
					cconfig: {
						column: 'contract_contractconfig',
						operator: 'isNotEmpty'
					}
			    }
			}
		},
		watch: {
			$route(){
				this.table = true
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContractForm : () => import('@/components/Contract/ContractForm'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
			ModalContractModifRapide: () => import('@/components/Contract/ModalContractModifRapidev2'),
			ModalSendContract: () => import('@/components/Contract/ModalSendContract'),
			ModalActDGLot : () => import('@/components/Gynecologie/ModalActDGLot'),
			ModalContractEnvoie : () => import('@/components/Contract/ModalContractEnvoie')
		},
	}
</script>
